import { createTheme, Grow } from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const theme = () =>
  createTheme({
    typography: {
      fontFamily: "'Figtree', sans-serif",
      allVariants: {
        letterSpacing: "normal",
        fontVariantLigatures: "no-contextual",
        WebkitFontSmoothing: "antialiased",
      },
      button: {
        fontWeight: "600",
        textTransform: "none",
      },
    },
    components: {
      MuiDialogActions: {
        styleOverrides: {
          root: {
            height: 60,
            padding: 23,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: ".9rem",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
        defaultProps: {
          TabIndicatorProps: {
            sx: {
              height: 3,
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
            },
          },
        },
      },
      MuiListItemText: {
        defaultProps: {
          primaryTypographyProps: {
            sx: { fontSize: ".9rem" },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "150ms",
            },
          },
          outlinedPrimary: {
            borderColor: "rgb(0,0,0,.2)",
            ":hover": {
              borderColor: "rgb(0,0,0,.3)",
            },
          },
          outlinedInherit: {
            borderColor: "rgb(0,0,0,.2)",
            ":hover": {
              borderColor: "rgb(0,0,0,.3)",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "150ms",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontWeight: "500",
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&& .MuiTouchRipple-rippleVisible": {
              animationDuration: "200ms",
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: "#474747",
          },
          tooltip: {
            borderRadius: 2,
            backgroundColor: "#474747",
            fontSize: ".875rem",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          sx: {
            cursor: "pointer",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 5,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: "#323232",
            color: "#fff",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontWeight: "500",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#F8F9FA",
            "&:hover": {
              backgroundColor: "#EFF1F3",
            },
            "&.Mui-focused": {
              backgroundColor: "#F8F9FA",
            },
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          component: "div",
          display: "flex",
          alignItems: "center",
        },
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            zIndex: 10,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: "500",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: "500",
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#1B72E8",
      },
      secondary: {
        main: "#3B428C",
      },
      error: {
        main: "#C5221F",
      },
      success: {
        main: "#2F7C31",
      },
      text: {
        main: "#4B4B4B",
      },
      capition: {
        main: "#B1AEAE",
      },
      warning: {
        main: "#C39305",
      },
      action: {
        active: "#5F6367",
      },
      mode: "light",
    },
  });

export default theme;
