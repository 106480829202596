import { Box } from "@mui/material";
import React from "react";

function ActionsFooter({ leftContent = <></>, rightContent = <></> }) {
  return (
    <Box mt={5} display={"flex"} alignItems={"center"} gap={1}>
      {leftContent}
      <Box flex={1} />
      {rightContent}
    </Box>
  );
}

export default ActionsFooter;
