import { Button } from "@mui/material";
import React, { memo } from "react";

function NextButton({ type = "submit", children, disabled, onClick, sx }) {
  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClick}
      variant="contained"
      disableElevation
      size="large"
      sx={{
        transition: "none",
        ":hover": {
          boxShadow: 2,
        },
        ...sx,
      }}
    >
      {children || "Avançar"}
    </Button>
  );
}

export default memo(NextButton);
