import { Circle } from "@mui/icons-material";
import { Box, LinearProgress, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useAuth } from "../../context/AuthContext";
import BoxContent from "./BoxContent";
import LogoHeader from "./LogoHeader";

function Container({ children }) {
  const { loading } = useAuth();

  return (
    <>
      <Box width={"100%"} maxWidth={460}>
        <BoxContent>
          <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />
          <LogoHeader />
          {children}
        </BoxContent>
      </Box>
      <Box width={"100%"} maxWidth={700}>
        <Stack
          justifyContent={"center"}
          mt={2}
          direction={"row"}
          alignItems={"center"}
          gap={1.5}
        >
          <Typography color="textSecondary" variant="caption">
            Versão 2.0.0
          </Typography>
          <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
          <Typography
            color="textSecondary"
            component={Link}
            href="https://www.notepay.com/legal/privacy"
            target="_blank"
            variant="caption"
          >
            Política de privacidade
          </Typography>
          <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
          <Typography
            color="textSecondary"
            component={Link}
            href="https://www.notepay.com/legal/terms"
            target="_blank"
            variant="caption"
          >
            Termos de uso
          </Typography>
          <Circle sx={{ fontSize: ".3rem" }} color="disabled" />
          <Typography color="textSecondary" variant="caption">
            Powered by{" "}
            <Typography
              component={Link}
              color="textSecondary"
              href="https://www.notepay.com"
              target="_blank"
              variant="inherit"
            >
              Notepay
            </Typography>
          </Typography>
        </Stack>
      </Box>
    </>
  );
}

export default Container;
