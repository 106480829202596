import { Typography } from "@mui/material";
import React from "react";

function Subtitle({ children, centered = true }) {
  return (
    <Typography
      component={"div"}
      textAlign={centered ? "center" : "left"}
      variant="body1"
      fontWeight={"500"}
      sx={{ opacity: 0.7 }}
    >
      {children}
    </Typography>
  );
}

export default Subtitle;
