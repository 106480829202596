import { Paper } from "@mui/material";
import React from "react";

function BoxContent({ children }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        width: "100%",
        boxShadow: { sm: "0px 1px 10px rgba(0,0,0,.03)", xs: 0 },
        borderRadius: { sm: 6, xs: 0 },
        minHeight: "300px",
        border: "none",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {children}
    </Paper>
  );
}

export default BoxContent;
