import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthAPIGateway } from "../../api/auth";
import ModalErrorBase from "../../components/ModalErrorBase";
import Content from "../../components/layout/Content";
import ContentHeader from "../../components/layout/ContentHeader";
import { useAuth } from "../../context/AuthContext";
import { formatPhone } from "../../utils/formatPhone";
import AccessStep from "./components/AccessStep";
import CompanyInfoStep from "./components/CompanyInfoStep";
import NameStep from "./components/NameStep";
import ResumeStep from "./components/ResumeStep";

function CreateAccount(props) {
  document.title = "Criar conta | Notepay";

  const navigate = useNavigate();

  const { setLoading } = useAuth();

  const [creationError, setCreationError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [password, setPassword] = useState("");

  const fromReactNative = (searchParams.get("fromReactNative") || "") === "1";
  const step = searchParams.get("step") || "0";

  const name = searchParams.get("name") || "";
  const lastname = searchParams.get("lastname") || "";
  const companyName = searchParams.get("companyName") || "";
  const country = searchParams.get("country") || "BR";
  const currency = searchParams.get("currency") || "BRL";
  const language = searchParams.get("language") || "PT";
  const phone = searchParams.get("phone") || "";
  const usersNum = searchParams.get("usersNum") || "";
  const hasSap = searchParams.get("hasSap") || "";
  const email = searchParams.get("email") || "";

  useEffect(() => {
    if (parseInt(step) < 2) {
      setPassword("");
    }
    if (parseInt(step) >= 2 && !password.trim()) {
      searchParams.set("step", "2");
      setSearchParams(searchParams);
    }
  }, [step, password]);

  const handleGoBackLogin = () => {
    navigate(`/`, {
      replace: true,
    });
  };

  const nextStep = () => {
    searchParams.set("step", (parseInt(step) + 1).toString());
    setSearchParams(searchParams);
  };
  const prevStep = () => {
    searchParams.set("step", (parseInt(step) - 1).toString());
    setSearchParams(searchParams, { replace: true });
  };

  const handleSubmitName = (name, lastname) => {
    searchParams.set("name", name);
    searchParams.set("lastname", lastname);
    setSearchParams(searchParams);
    nextStep();
  };

  const handleSubmitCompanyInfo = (data) => {
    Object.keys(data).forEach((field) => {
      searchParams.set(field, data[field]);
    });
    setSearchParams(searchParams);
    nextStep();
  };

  const handleSubmitAccess = (email, pass) => {
    setPassword(pass);
    searchParams.set("email", email);
    setSearchParams(searchParams);
    nextStep();
  };

  //CREATE ACCOUNT

  const handleCreateAccount = async () => {
    setLoading(true);
    try {
      const { data } = await AuthAPIGateway.createAccount({
        email,
        password,
        companyName,
        currency,
        lastname,
        name,
        hasSap,
        phone: formatPhone(phone),
        usersNum,
      });
      navigate(`/confirm-creation-email?email=${email}`, {
        replace: true,
      });
    } catch (error) {
      setCreationError(
        error?.response?.data?.message?.toString() ||
          "Erro não esperado ao criar conta. Tente novamente"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Content>
        <Collapse unmountOnExit in={step === "0"}>
          <ContentHeader
            title="Cadastrar sua empresa na Notepay"
            subtitle="Vamos começar com o seu nome."
          />
          <NameStep
            initialLastname={lastname}
            initialName={name}
            onSubmit={handleSubmitName}
            onPrev={handleGoBackLogin}
            fromReactNative={fromReactNative}
          />
        </Collapse>
        <Collapse unmountOnExit in={step === "1"}>
          <ContentHeader
            title={`Olá, ${name}.`}
            subtitle="Insira algumas informações sobre a sua empresa."
          />
          <CompanyInfoStep
            onPrev={prevStep}
            onSubmit={handleSubmitCompanyInfo}
            initialValues={{
              language,
              country,
              currency,
              usersNum,
              hasSap,
              companyName,
              phone,
            }}
          />
        </Collapse>
        <Collapse unmountOnExit in={step === "2"}>
          <ContentHeader
            title={`Vamos criar o seu acesso`}
            subtitle="Insira o seu e-mail e senha"
          />
          <AccessStep
            navigate={navigate}
            initialEmail={email}
            onSubmit={handleSubmitAccess}
            onPrev={prevStep}
          />
        </Collapse>
        <Collapse in={step === "3"}>
          <ResumeStep
            onPrev={prevStep}
            onCreate={handleCreateAccount}
            {...{
              name,
              lastname,
              email,
              companyName,
              currency,
              country,
              language,
            }}
          />
        </Collapse>
      </Content>
      <ModalErrorBase
        open={Boolean(creationError)}
        title={"Erro ao criar conta"}
        subtitle={creationError}
        onClose={() => setCreationError(null)}
      />
    </>
  );
}

export default CreateAccount;
